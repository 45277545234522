import { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useAxiosSetup = (accessToken, setAccessToken) => {
  const history = useHistory();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          // Token expired, redirect to login
          setAccessToken('');
          localStorage.removeItem('accessToken');
          history.push('/login');
        }
        return Promise.reject(error);
      }
    );

    // Clean up interceptors on unmount
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [accessToken, setAccessToken, history]);
};

export default useAxiosSetup;