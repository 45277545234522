import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CardActionArea,
  IconButton,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PlaceIcon from '@mui/icons-material/Place';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SessionDetails from './SessionDetails';

const ListSessions = ({ accessToken }) => {
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState('');
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [level, setLevel] = useState('');
  const [customLevel, setCustomLevel] = useState('');
  const [open, setOpen] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [showSessionDetails, setShowSessionDetails] = useState(false);

  useEffect(() => {
    fetchSessions();
  }, [accessToken]);

  const fetchSessions = async () => {
    try {
      const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query', {
        access_token: accessToken,
      },{
        params: {
          type: 'get_sessions'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setSessions(response.data);
      console.log('fetchSessions response:', response);
    } catch (error) {
      setError('Failed to fetch sessions');
      console.error('Error fetching sessions:', error);
    }
  };

  const handleNewSession = async (e) => {
    e.preventDefault();
    const finalLevel = level === 'Other' ? customLevel : level;
    try {
      const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query', {
        access_token: accessToken,
      },{
        params: {
          type: 'create_session',
          title: title,
          location: location,
          level: finalLevel
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setTitle('');
      setLevel('');
      setCustomLevel('');
      setOpen(false);
      fetchSessions();
    } catch (error) {
      setError('Failed to create session');
      console.error('Error creating session:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleCardClick = async (session_id) => {
    try {
      const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query', {
        access_token: accessToken,
      },{
        params: {
          type: 'load_session_data',
          session_id: session_id
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setSessionData(response.data);
      setShowSessionDetails(true);
    } catch (error) {
      setError('Failed to load session data');
      console.error('Error loading session data:', error);
    }
  };

  const handleBackToSessions = () => {
    setShowSessionDetails(false);
    setSessionData(null);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      {!showSessionDetails ? (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Sessions
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          <Grid container spacing={3}>
            {sessions.map(session => (
              <Grid item xs={12} sm={6} md={4} key={session.session_id}>
                <Card>
                  <CardActionArea onClick={() => handleCardClick(session.session_id)}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {session.title}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <BookmarkIcon />
                        <Typography variant="body1" component="span" sx={{ ml: 1 }}>
                          Level {session.level}
                        </Typography>
                      </Box>
                      {session.location ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <PlaceIcon />
                          <Typography variant="body1" component="span" sx={{ ml: 1 }}>
                            {session.location}
                          </Typography>
                        </Box>
                      ) : ""}
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PeopleAltIcon />
                        <Typography variant="body1" component="span" sx={{ ml: 1 }}>
                          {session.catechists.map(catechist => `${catechist.first_name} ${catechist.last_name}`).join(', ')}
                        </Typography>
                      </Box>
                      <Typography variant="caption" gutterBottom>
                        Created: {formatDate(session.date_added)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleClickOpen}
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
            <AddIcon />
          </Fab>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>New Session</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Create a new session
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Title"
                type="text"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <TextField
                margin="dense"
                label="Atrium Location"
                type="text"
                fullWidth
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Level</InputLabel>
                <Select
                  value={level}
                  onChange={handleLevelChange}
                  label="Level"
                  required
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              {level === 'Other' && (
                <TextField
                  margin="dense"
                  label="Custom Level"
                  type="text"
                  fullWidth
                  value={customLevel}
                  onChange={(e) => setCustomLevel(e.target.value)}
                  required
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleNewSession} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box>
          <Tooltip title="List all Sessions">
            <IconButton onClick={handleBackToSessions} sx={{ mb: 3 }}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <SessionDetails sessionData={sessionData} accessToken={accessToken} />
        </Box>
      )}
    </Box>
  );
};

export default ListSessions;