import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ChangePasswordForm = ({ accessToken }) => {
  const [previousPassword, setPreviousPassword] = useState('');
  const [proposedPassword, setProposedPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    console.log('Access token in ChangePasswordForm:', accessToken);
  }, [accessToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Access token in handleSubmit:', accessToken);
    try {
      const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/change-password', {
        access_token: accessToken,
        previous_password: previousPassword,
        proposed_password: proposedPassword
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMessage('Password changed successfully!');
    } catch (error) {
      setMessage('Failed to change password. Please try again.');
    }
  };

  return (
    <div>
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Current Password:</label>
          <input
            type="password"
            value={previousPassword}
            onChange={(e) => setPreviousPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>New Password:</label>
          <input
            type="password"
            value={proposedPassword}
            onChange={(e) => setProposedPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Change Password</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ChangePasswordForm;