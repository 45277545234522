import React, { useState } from 'react';
import axios from 'axios';

const InitiatePasswordResetForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/initiate-password-reset', {
        email: email,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Password reset initiation failed. Please try again.');
      console.error('Error initiating password reset:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Initiate Password Reset</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default InitiatePasswordResetForm