import React, { useState } from 'react';
import { Box, Button, TextField, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import axios from 'axios';
import qs from 'qs';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import BoyIcon from '@mui/icons-material/Boy';

const AddObservationForm = ({ accessToken, session_id, child_id, work_id, onObservationAdded }) => {
  const [flags, setFlags] = useState([]);
  const [text, setText] = useState('');

  const handleFlagChange = (event, newFlags) => {
    setFlags(newFlags);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const presented_flag = flags.includes('presented');
      const chosen_flag = flags.includes('chosen');

      await axios.post(`https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query`, {
        access_token: accessToken
      }, {
        params: {
          session_id: session_id,
          child_id: child_id,
          work_id: work_id,
          presented_flag: presented_flag,
          chosen_flag: chosen_flag,
          text: text,
          type: 'add_observation'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setFlags([]);
      setText('');
      onObservationAdded();
    } catch (error) {
      console.error('Failed to add observation:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mb: 2 }}>
      <Typography variant="h6">Add Observation</Typography>
      <ToggleButtonGroup
        value={flags}
        onChange={handleFlagChange}
        aria-label="Observation Flags"
        sx={{ mb: 2 }}
      >
        <ToggleButton value="presented" aria-label="Presented">
          <EscalatorWarningIcon /> Presented
        </ToggleButton>
        <ToggleButton value="chosen" aria-label="Chosen">
          <BoyIcon /> Chosen
        </ToggleButton>
      </ToggleButtonGroup>
      <TextField
        label="Observation Text"
        multiline
        rows={4}
        fullWidth
        value={text}
        onChange={(e) => setText(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </Box>
  );
};

export default AddObservationForm;