import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Fab,
  Tooltip,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import AddChildForm from './AddChildForm';

const EnrollChildrenForm = ({ accessToken, currentSessionId, onChildrenEnrolled }) => {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState([]);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [loading, setLoading] = useState(false);
  const [childFormOpen, setChildFormOpen] = useState(false);

  useEffect(() => {
    if (open) {
      fetchChildren();
    }
  }, [open]);

  const fetchChildren = async (newChildId = null) => {
    setLoading(true);
    try {
      const response = await axios.post(`https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query`, {
        access_token: accessToken
      }, {
        params: {
          exclude_session_id: currentSessionId,
          type: 'get_children'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      //console.log('newChildId:',newChildId)
      //console.log('fetchChildren response data:', response.data); // Add this line for debugging
      setChildren(response.data);
      // If newChildId is provided, add the new child to selectedChildren
        if (newChildId) {
            const newChild = response.data.find(child => child.child_id === newChildId);
            if (newChild) {
            setSelectedChildren(prevSelectedChildren => [...prevSelectedChildren, newChild]);
            }
        }
    } catch (error) {
      console.error('Failed to fetch children:', error);
    }
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChildren([]);
  };

  const handleEnrollChildren = async () => {
    try {
      await Promise.all(selectedChildren.map(child => 
        axios.post(`https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query`, {
          access_token: accessToken
        }, {
          params: {
            session_id: currentSessionId,
            child_id: child.child_id,
            type: 'enroll_child_in_session'
          },
          headers: {
            'Content-Type': 'application/json'
          }
        })
      ));
      handleClose();
      onChildrenEnrolled();
    } catch (error) {
      console.error('Failed to enroll children:', error);
    }
  };

  const handleAddChildOpen = () => {
    setChildFormOpen(true);
  };

  const handleAddChildClose = () => {
    setChildFormOpen(false);
  };

  const handleChildAdded = (newChildId) => {
    handleAddChildClose();
    fetchChildren(newChildId);
  };

  return (
    <Box>
      <Tooltip title="Enroll Children">
        <Fab color="primary" aria-label="enroll" onClick={handleClickOpen} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enroll Children in Session</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To enroll children in this session, please select them from the list below or create a new child record.
          </DialogContentText>
          {loading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              multiple
              options={children}
              getOptionLabel={(child) => `${child.first_name} ${child.last_name}`}
              value={selectedChildren}
              onChange={(event, newValue) => setSelectedChildren(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select Children" placeholder="Children" />
              )}
            />
          )}
          <Button onClick={handleAddChildOpen} color="primary">
            Create New Child Record
          </Button>
          <AddChildForm
            accessToken={accessToken}
            open={childFormOpen}
            onClose={handleAddChildClose}
            onChildAdded={handleChildAdded}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEnrollChildren} color="primary" disabled={selectedChildren.length === 0}>
            Enroll
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EnrollChildrenForm;