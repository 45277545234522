import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory} from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import LoginRegisterForm from './LoginRegisterForm';
import VerifyEmail from './VerifyEmail';
import InitiatePasswordResetForm from './InitiatePasswordResetForm';
import ResetPasswordForm from './ResetPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';
import ListSessions from './ListSessions';
import ListChildren from './ListChildren';
import UserProfile from './UserProfile';
import AppDrawer from './AppDrawer';
import {ThemeProvider, Toolbar, Box } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import theme from './theme'; // Import your custom theme
import useAxiosSetup from './axiosSetup';

const App = () => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || ''); // Get this from your authentication logic
  const history = useHistory();

  useAxiosSetup(accessToken, setAccessToken);


  const handleLogin = (token) => {
    setAccessToken(token);
    localStorage.setItem('accessToken', token); // Store token in local storage
  };

  const handleLogout = (history) => {
    setAccessToken('');
    localStorage.removeItem('accessToken');
    history.push('/login');
  };


  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      return decoded.exp < currentTime;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return true;
    }
  };

  useEffect(() => {
    if (accessToken && isTokenExpired(accessToken)) {
      console.log('Access token expired');
      setAccessToken('');
      localStorage.removeItem('accessToken');
    }
  }, [accessToken]);

  useEffect(() => {
    console.log('accessToken state changed:', accessToken);
    if (accessToken) {
      console.log('Access token set:', accessToken);
    }
  }, [accessToken]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <AppDrawer theme={theme} accessToken={accessToken} onLogout={handleLogout}/>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <Switch>
              <Route path="/verify">
                <VerifyEmail />
              </Route>
              <Route path="/forgot-password">
                <InitiatePasswordResetForm />
              </Route>
              <Route path="/reset-password">
                <ResetPasswordForm />
              </Route>
              <Route path="/change-password">
                <ChangePasswordForm accessToken={accessToken} />
              </Route>
              <Route path="/login">
                <LoginRegisterForm onLogin={handleLogin} />
              </Route>
              <Route path="/account">
                <UserProfile />
              </Route>
              <Route path="/children">
                <ListChildren />
              </Route>
              <Route path="/sessions">
                {accessToken ? (
                  <ListSessions accessToken={accessToken} />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
              <Route path="/">
                {accessToken ? (
                  <Redirect to="/sessions" />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
            </Switch>
          </Box>
        </Box>
      </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;