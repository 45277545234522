import React, { useState,useEffect  } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import BoyIcon from '@mui/icons-material/Boy';
import AddObservationForm from './AddObservationForm';
import formatDate from './utils';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';



import axios from 'axios';

const ObservationCards = ({ accessToken, session, child, work, onObservationAdded, userEmail}) => {
  const [editingObs, setEditingObs] = useState(null);
  const [editDate, setEditDate] = useState(new Date());
  const [editText, setEditText] = useState('');
  const [editPresented, setEditPresented] = useState(false);
  const [editChosen, setEditChosen] = useState(false);
  const [deletingObs, setDeletingObs] = useState(null);
  const [editFlags, setEditFlags] = useState([]);

    

  const handleEditClick = (obs) => {
    setEditingObs(obs);
    setEditDate(new Date(obs.date));
    setEditText(obs.text);
    setEditFlags(obs.flags);
    setEditPresented(obs.flags.includes('presented'));
    setEditChosen(obs.flags.includes('chosen'));
  };

  const handleEditClose = () => {
    setEditingObs(null);
  };

  const handleEditSubmit = async () => {

    try {
      const response = await axios.post(`https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query`, {
        access_token: accessToken
      }, {
        params: {
          child_id: child.child_id,
          observation_id: editingObs.observation_id,
          date: editDate.toISOString(),
          presented_flag: editPresented,
          chosen_flag: editChosen,
          text: editText,
          type: 'edit_observation'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("edit observation response:",response);
      setEditingObs(null);
      onObservationAdded(); // refresh observations after editing
    } catch (error) {
      console.error('Failed to edit observation:', error);
    }
  };

  const handleDeleteClick = (obs) => {
    setDeletingObs(obs);
  };

  const handleDeleteClose = () => {
    setDeletingObs(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.post(`https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query`, {
        access_token: accessToken
      }, {
        params: {
          child_id: child.child_id,
          observation_id: deletingObs.observation_id,
          type: 'delete_observation'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("delete observation response:", response);
      setDeletingObs(null);
      onObservationAdded(); // refresh observations after deleting
    } catch (error) {
      console.error('Failed to delete observation:', error);
    }
  };

  const handleFlagChange = (event, newFlags) => {
    setEditFlags(newFlags);
    setEditPresented(newFlags.includes('presented'));
    setEditChosen(newFlags.includes('chosen'));
  };

  return (
    <div>
      <AddObservationForm
        accessToken={accessToken}
        session_id={session.session.session_id}
        child_id={child.child_id}
        work_id={work.work_id}
        onObservationAdded={onObservationAdded}
      />
      {child.observations
        .filter(obs => obs.work_id === work.work_id)
        .map(obs => {
          const catechist = session.catechists.find(c => c.catechist_email === obs.catechist_email);
          const catechistName = catechist ? `${catechist.first_name} ${catechist.last_name}` : obs.catechist_email;
          const isPresented = obs.flags.includes('presented');
          const isChosen = obs.flags.includes('chosen');
          const canEdit = obs.catechist_email === userEmail;
          return (
            <Card key={obs.observation_id} sx={{ marginBottom: 2 }}>
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                  <Box display="flex" alignItems="center">
                    {isPresented && <EscalatorWarningIcon />}
                    {isChosen && <BoyIcon />}
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {`${obs.text}`}
                    </Typography>
                  </Box>
                  {canEdit && (
                    <Box>
                        <IconButton onClick={() => handleEditClick(obs)}>
                        <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(obs)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                  )}
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {`${catechistName}`}, {`${formatDate(obs.date)}`}
                </Typography>
              </CardContent>
            </Card>
          );
        })}

      {editingObs && (
        <Dialog open={Boolean(editingObs)} onClose={handleEditClose}>
          <DialogTitle>Edit Observation</DialogTitle>
          <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              value={editDate}
              onChange={(newValue) => setEditDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            </LocalizationProvider>
            <TextField
              label="Observation Text"
              multiline
              rows={4}
              fullWidth
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              sx={{ mt: 2 }}
            />

            <ToggleButtonGroup
              value={editFlags}
              onChange={handleFlagChange}
              aria-label="Flag Selection"
              sx={{ mt: 2 }}
            >
              <ToggleButton value="presented" aria-label="Presented">
                <EscalatorWarningIcon /> Presented
              </ToggleButton>
              <ToggleButton value="chosen" aria-label="Chosen">
                <BoyIcon /> Chosen
              </ToggleButton>
            </ToggleButtonGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {deletingObs && (
        <Dialog open={Boolean(deletingObs)} onClose={handleDeleteClose}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this observation? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ObservationCards;