import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import axios from 'axios';

const AddChildForm = ({ accessToken, open, onClose, onChildAdded }) => {
  const [childFirstName, setChildFirstName] = useState('');
  const [childLastName, setChildLastName] = useState('');
  const [parentEmail, setParentEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query`, {
        access_token: accessToken
      }, {
        params: {
          first_name: childFirstName,
          last_name: childLastName,
          parent_email: parentEmail || '',
          type: 'create_child_record'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const newChildId = response.data; // the response contains the new child_id
      //console.log('AddChildForm newChildId:',newChildId)
      setChildFirstName('');
      setChildLastName('');
      setParentEmail('');
      onChildAdded(newChildId);
    } catch (error) {
      console.error('Failed to create child record:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Child</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add a new child, please enter their details here.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="First Name"
          type="text"
          fullWidth
          value={childFirstName}
          onChange={(e) => setChildFirstName(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Last Name"
          type="text"
          fullWidth
          value={childLastName}
          onChange={(e) => setChildLastName(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Parent Email"
          type="email"
          fullWidth
          value={parentEmail}
          onChange={(e) => setParentEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddChildForm;