import React, { useState } from 'react';
import { Tabs, Tab, Box, TextField, Button, Typography, Link, Modal } from '@mui/material';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import theme from './theme'; // Import your custom theme

const LoginRegisterForm = ({ onLogin }) => {
  const [tab, setTab] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
  const history = useHistory();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tab === 1 && password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    const url = tab === 0 ? 'https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/login' : 'https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/register';
    try {
      const payload = { email, password };
      if (tab === 1) {
        payload.firstName = firstName;
        payload.lastName = lastName;
      }
      const response = await axios.post(url, payload);
      if (tab === 0) { // If logging in
        const { accessToken } = response.data;
        console.log('Login successful, received access_token:', accessToken);
        onLogin(accessToken);
        history.push('/sessions'); // Redirect to sessions page after login
      }
      setMessage(tab === 0 ? 'Login successful!' : 'Registration successful! Please check your email for the verification link.');
    } catch (error) {
      setMessage(tab === 0 ? 'Login failed. Please check your credentials and try again.' : 'Registration failed. Please try again.');
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    

    try {
        const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/initiate-password-reset', {
            email: forgotPasswordEmail,
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

      setForgotPasswordMessage('Password reset email sent. Please check your email.');
    } catch (error) {
      setForgotPasswordMessage('Failed to send password reset email. Please try again.');
      console.error('Forgot password error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
        
    <Box sx={{ width: '300px', margin: 'auto', marginTop: '50px' }}>
      <center>
        <img src="sheep_logo.png" alt="Sheepfolio logo" width='150px'/>
        <h1 className="alegreya-logo">Sheepfolio</h1>
      </center>
      
      <Tabs value={tab} onChange={handleTabChange} centered>
        <Tab label="Login" />
        <Tab label="Register" />
      </Tabs>
      <Box sx={{ padding: '16px' }}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            margin="normal"
          />
          {tab === 1 && (
            <>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                margin="normal"
              />
              <TextField
                fullWidth
                label="First Name"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                margin="normal"
              />
              <TextField
                fullWidth
                label="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                margin="normal"
              />
            </>
          )}
          <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            {tab === 0 ? 'Login' : 'Register'}
          </Button>
        </form>
        {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
        {tab === 0 && (
          <Link
            component="button"
            variant="body2"
            onClick={() => setForgotPasswordOpen(true)}
            sx={{ mt: 2, display: 'block', textAlign: 'center' }}
          >
            Forgot Password?
          </Link>
        )}
      </Box>
      <Modal
        open={forgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
        aria-labelledby="forgot-password-modal-title"
        aria-describedby="forgot-password-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="forgot-password-modal-title" variant="h6" component="h2">
            Forgot Password
          </Typography>
          <form onSubmit={handleForgotPasswordSubmit}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              required
              margin="normal"
            />
            <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Send Reset Email
            </Button>
          </form>
          {forgotPasswordMessage && <Typography color="error" sx={{ mt: 2 }}>{forgotPasswordMessage}</Typography>}
        </Box>
      </Modal>
    </Box>
    </div>
  );
};

export default LoginRegisterForm;