import React, { useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography, Link} from '@mui/material';
//import { Link } from 'react-router-dom';

const ResetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/confirm-password-reset', {
        email: email,
        code: code,
        new_password: newPassword
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMessage('Password reset successfully!');
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div>
      <Box sx={{ width: '300px', margin: 'auto', marginTop: '50px' }}>
      <h2>Reset Password</h2>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          margin="normal"
        />
        <TextField
          fullWidth
          label="Verification Code"
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
          margin="normal"
        />
        <TextField
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          margin="normal"
        />
        <TextField
          fullWidth
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          margin="normal"
        />
        <Button fullWidth type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Reset Password
        </Button>
      </form>
      {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
      <Link href="../login">
        <Typography id="goto-login" variant="h6" component="h2">
          Go to login page
        </Typography>
      </Link>
      </Box>
    </div>
  );
};

export default ResetPasswordForm;