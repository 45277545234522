import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  Grid,
  Fab,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ToggleButton, 
  ToggleButtonGroup
} from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import BoyIcon from '@mui/icons-material/Boy';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddIcon from '@mui/icons-material/Add';
import AddObservationForm from './AddObservationForm';
import EnrollChildrenForm from './EnrollChildrenForm'; // Import EnrollChildrenForm
import AddCatechistForm from './AddCatechistForm';
import formatDate from './utils';
import ObservationCards from './ObservationCards';
import PlaceIcon from '@mui/icons-material/Place';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const SessionDetails = ({ sessionData, accessToken }) => {
  const [groupByWork, setGroupByWork] = useState(true);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [filteredData, setFilteredData] = useState(sessionData);
  const [session, setSession] = useState(sessionData);
  const [addCatechistOpen, setAddCatechistOpen] = useState(false);

  useEffect(() => {
    filterData();
  }, [selectedChildren, selectedWorks, groupByWork, session]);

  const fetchSessionData = async () => {
    try {
      const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query', {
        access_token: accessToken,
      },{
        params: {
          type: 'load_session_data',
          session_id: session.session.session_id
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setSession(response.data);
    } catch (error) {
      console.error('Failed to fetch session data:', error);
    }
  };
  const filterData = () => {
    const filteredChildren = session.children.filter(child => selectedChildren.length === 0 || selectedChildren.includes(child.first_name));
    //console.log('Filtered Children:', filteredChildren);
  
    const filteredWorks = session.works.filter(work => selectedWorks.length === 0 || selectedWorks.includes(work.title));
    //console.log('Filtered Works:', filteredWorks);
  
    const groupedBySubject = filteredWorks.reduce((acc, work) => {
      if (!acc[work.subject]) {
        acc[work.subject] = [];
      }
      acc[work.subject].push(work);
      return acc;
    }, {});
  
    if (groupByWork) {
      const groupedByWork = Object.entries(groupedBySubject).map(([subject, works]) => ({
        subject,
        works: works.map(work => ({
          ...work,
          children: filteredChildren.map(child => ({
            ...child,
            observations: child.observations.filter(obs => obs.work_id === work.work_id).sort((a, b) => new Date(b.date) - new Date(a.date))
          }))
        }))
      }));
      //console.log('Grouped By Work:', groupedByWork);
      setFilteredData({ ...session, subjects: groupedByWork });
    } else {
      const groupedByChild = filteredChildren.map(child => ({
        ...child,
        works: filteredWorks.map(work => ({
          ...work,
          observations: child.observations.filter(obs => obs.work_id === work.work_id).sort((a, b) => new Date(b.date) - new Date(a.date))
        }))
      }));
      //console.log('Grouped By Child:', groupedByChild);
      setFilteredData({ ...session, children: groupedByChild });
    }
  };
  /*
  const filterData = () => {
    const filteredChildren = session.children.filter(child => selectedChildren.length === 0 || selectedChildren.includes(child.first_name));
    console.log('Filtered Children:', filteredChildren);
  
    const filteredWorks = session.works.filter(work => selectedWorks.length === 0 || selectedWorks.includes(work.title));
    console.log('Filtered Works:', filteredWorks);
  
    if (groupByWork) {
      const groupedByWork = filteredWorks.map(work => {
        const workChildren = filteredChildren.map(child => ({
          ...child,
          observations: child.observations.filter(obs => obs.work_id === work.work_id).sort((a, b) => new Date(b.date) - new Date(a.date))
        }));
        console.log(`Children for work ${work.work_id}:`, workChildren);  // Log to debug
        return {
          ...work,
          children: workChildren
        };
      });
      console.log('Grouped By Work:', groupedByWork);
      setFilteredData({ ...session, works: groupedByWork });
    } else {
      const groupedByChild = filteredChildren.map(child => ({
        ...child,
        works: filteredWorks.map(work => ({
          ...work,
          observations: child.observations.filter(obs => obs.work_id === work.work_id).sort((a, b) => new Date(b.date) - new Date(a.date))
        }))
      }));
      console.log('Grouped By Child:', groupedByChild);
      setFilteredData({ ...session, children: groupedByChild });
    }
  };
  
  const filterData = () => {
    const filteredChildren = session.children.filter(child => selectedChildren.length === 0 || selectedChildren.includes(child.first_name));
    console.log('Filtered Children:', filteredChildren);
    const filteredWorks = session.works.filter(work => selectedWorks.length === 0 || selectedWorks.includes(work.title));
    console.log('Filtered Works:', filteredWorks);

    if (groupByWork) {
      const groupedByWork = filteredWorks.map(work => ({
        ...work,
        children: filteredChildren.filter(child => 
          child.observations.some(obs => obs.work_id === work.work_id)
        ).map(child => ({
          ...child,
          observations: child.observations.filter(obs => obs.work_id === work.work_id).sort((a, b) => new Date(b.date) - new Date(a.date))
        }))
      }));
      console.log('Grouped By Work:', groupedByWork);
      setFilteredData({ ...session, works: groupedByWork });
    } else {
      const groupedByChild = filteredChildren.map(child => ({
        ...child,
        works: filteredWorks.filter(work =>
          child.observations.some(obs => obs.work_id === work.work_id)
        ).map(work => ({
          ...work,
          observations: child.observations.filter(obs => obs.work_id === work.work_id).sort((a, b) => new Date(b.date) - new Date(a.date))
        }))
      }));
      setFilteredData({ ...session, children: groupedByChild });
    }
  };*/

  const hasFlag = (observations, flag) => {
    return (observations || []).some(obs => (obs.flags || []).includes(flag));
  };

  const handleGroupByChange = (event, newGroupBy) => {
    if (newGroupBy !== null) {
      setGroupByWork(newGroupBy);
    }
  };

  const handleSelectAllChildren = () => {
    setSelectedChildren(session.children.map(child => child.first_name));
  };

  const handleSelectAllWorks = () => {
    setSelectedWorks(session.works.map(work => work.title));
  };

  const handleObservationAdded = async () => {
    await fetchSessionData();  // Fetch latest data after observation is added
    filterData();  // Apply filtering to the new data
  };

  const handleChildrenEnrolled = async () => {
    await fetchSessionData();  // Fetch latest data after children are enrolled
    filterData();  // Apply filtering to the new data
  };

  const renderIcons = (observations) => {
    const hasPresented = hasFlag(observations, 'presented');
    const hasChosen = hasFlag(observations, 'chosen');
    
    return (
      <>
        {hasPresented && (
          <Tooltip title="Presented">
            <EscalatorWarningIcon color="primary" sx={{ marginRight: 1 }} />
          </Tooltip>
        )}
        {hasChosen && (
          <Tooltip title="Chosen">
            <BoyIcon color="primary" />
          </Tooltip>
        )}
      </>
    );
  };

  const handleAddCatechistOpen = () => {
    setAddCatechistOpen(true);
  };
  
  const handleAddCatechistClose = () => {
    setAddCatechistOpen(false);
  };
  
  const handleCatechistAdded = async () => {
    await fetchSessionData();  // Fetch latest data after catechist is added
    filterData();  // Apply filtering to the new data
  };

  return (
    <Box sx={{ padding: 0 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        {`Level ${session.session.level}: ${session.session.title}`}
      </Typography>
      {session.session.location ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PlaceIcon />
          <Typography variant="body1" component="span" sx={{ ml: 1 }}>
            {session.session.location}
          </Typography>
        </Box>
      ) : ""}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PeopleAltIcon /> Catechists: 
        <Typography variant="body1" component="span" sx={{ ml: 1 }}>
          {session.catechists.map(catechist => `${catechist.first_name} ${catechist.last_name}`).join(', ')}
        </Typography>
        <Tooltip title="Add Catechist">
          <IconButton color="primary" onClick={handleAddCatechistOpen}>
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <AddCatechistForm
        accessToken={accessToken}
        currentSessionId={session.session.session_id}
        open={addCatechistOpen}
        onClose={handleAddCatechistClose}
        onCatechistAdded={handleCatechistAdded}
      />
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            options={session.children.map(child => child.first_name)}
            value={selectedChildren}
            onChange={(event, newValue) => setSelectedChildren(newValue)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Select children" placeholder="Select children" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            options={session.works.map(work => work.title)}
            value={selectedWorks}
            onChange={(event, newValue) => setSelectedWorks(newValue)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Select works" placeholder="Select works"  />
            )}
          />
        </Grid>
      </Grid>
      <ToggleButtonGroup
        value={groupByWork}
        exclusive
        onChange={handleGroupByChange}
        aria-label="Group by"
        sx={{ marginBottom: 2 }}
      >
        <ToggleButton value={true} aria-label="Group by Work">
          Group by Work
        </ToggleButton>
        <ToggleButton value={false} aria-label="Group by Child">
          Group by Child
        </ToggleButton>
      </ToggleButtonGroup>
      {groupByWork ? (
        (filteredData.subjects || []).map(({ subject, works }) => (
          <Box key={subject} sx={{ marginBottom: 3 }}>
            <Typography variant="h5" component="h3" gutterBottom>
              {subject}
            </Typography>
            {works.map(work => (
              <Accordion key={work.work_id}>
                <AccordionSummary>
                  <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <ExpandMoreIcon sx={{ marginRight: 2 }} />
                    <Typography sx={{ flexGrow: 1 }}>{work.title}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {(work.children || []).map(child => (
                    <Accordion key={child.child_id}>
                      <AccordionSummary>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                          <ExpandMoreIcon sx={{ marginRight: 2 }} />
                          <Typography sx={{ flexGrow: 1 }}>{`${child.first_name} ${child.last_name}`}</Typography>
                          {renderIcons(child.observations)}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ObservationCards 
                          accessToken={accessToken} 
                          session={session} 
                          child={child}
                          work={work}
                          onObservationAdded={handleObservationAdded}
                          userEmail={session.user_email}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ))
      ) : (
        (filteredData.children || []).map(child => (
          <Accordion key={child.child_id}>
            <AccordionSummary>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <ExpandMoreIcon sx={{ marginRight: 2 }} />
                <Typography sx={{ flexGrow: 1 }}>{`${child.first_name} ${child.last_name}`}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {Object.entries((child.works||[]).reduce((acc, work) => {
                if (!acc[work.subject]) {
                  acc[work.subject] = [];
                }
                acc[work.subject].push(work);
                return acc;
              }, {})).map(([subject, works]) => (
                <Box key={subject} sx={{ marginBottom: 2 }}>
                  <Typography variant="h6" component="h4" gutterBottom>
                    {subject}
                  </Typography>
                  {works.map(work => (
                    <Accordion key={work.work_id}>
                      <AccordionSummary>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                          <ExpandMoreIcon sx={{ marginRight: 2 }} />
                          <Typography sx={{ flexGrow: 1 }}>{work.title}</Typography>
                          {renderIcons(work.observations)}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ObservationCards 
                          accessToken={accessToken} 
                          session={session} 
                          child={child}
                          work={work}
                          onObservationAdded={handleObservationAdded}
                          userEmail={session.user_email}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      )}
      <EnrollChildrenForm
        accessToken={accessToken}
        currentSessionId={session.session.session_id}
        onChildrenEnrolled={handleChildrenEnrolled}
      />
    </Box>
  );
};

export default SessionDetails;