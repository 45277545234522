import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const VerifyEmail = () => {
  const [message, setMessage] = useState('Verifying...');
  const [verificationTriggered, setVerificationTriggered] = useState(false); // Add state to track verification status
  const history = useHistory();

  useEffect(() => {
    if (verificationTriggered) return; // If verification already triggered, do nothing

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email'); // No encoding needed here
    const code = urlParams.get('code'); // No encoding needed here

    const verifyEmail = async () => {
      try {
        const response = await axios.post('https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/verify', {
          email: decodeURIComponent(email),  // Decode URL-encoded email
          code: decodeURIComponent(code)  // Decode URL-encoded code
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setMessage('Email verified successfully!');
        setTimeout(() => {
          history.push('/login');
        }, 3000); // Redirect to login after 3 seconds
      } catch (error) {
        setMessage('Email verification failed. Please try again.');
        console.error('Verification error:', error.response ? error.response.data : error.message);
      }
    };

    if (code && email) {
      
      verifyEmail();
    } else {
      setMessage('Invalid verification link.');
    }
  }, [history]);

  return (
    <div>
      <h2>{message}</h2>
    </div>
  );
};

export default VerifyEmail;