import React from 'react';
import { withRouter } from 'react-router-dom';
import { styled} from '@mui/material/styles';
import {
  AppBar,
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  AccountCircle,
  Face,
  EventNote,
  ExitToApp, 
  LockOpen 
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import theme from './theme'; // Import your custom theme

const drawerWidth = 240;


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const AppDrawer = ({ theme, accessToken, onLogout }) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [
    { text: 'Sessions', icon: <EventNote /> },
    { text: 'Children', icon: <Face /> },
    { text: 'Account', icon: <AccountCircle /> },
  ];

  const handleMenuClick = (menuItem) => {
    switch (menuItem) {
      case 'Sessions':
        history.push('/sessions');
        break;
      case 'Children':
        history.push('/children');
        break;
      case 'Account':
        history.push('/account');
        break;
      case 'Login':
          history.push('/login');
          break;
      default:
        break;
    }
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <div className='alegreya-logo'>Sheepfolio</div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text} onClick={() => handleMenuClick(item.text)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={accessToken ? () => onLogout(history) : () => handleMenuClick('Login')}>
            <ListItemIcon>{accessToken ? <ExitToApp /> : <LockOpen />}</ListItemIcon>
            <ListItemText primary={accessToken ? 'Logout' : 'Login'} />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default withRouter(AppDrawer);