import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import axios from 'axios';

const AddCatechistForm = ({ accessToken, currentSessionId, open, onClose, onCatechistAdded }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://c3j3j3ji8k.execute-api.us-east-2.amazonaws.com/prod/db-query`, {
        access_token: accessToken
      }, {
        params: {
          session_id: currentSessionId,
          new_catechist_email: email,
          type: 'add_catechist_to_session'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('add catechist response:', response);
      setEmail('');
      onCatechistAdded(); // Notify parent component to update catechist list
      onClose(); // Close the modal
    } catch (error) {
      console.error('Failed to add catechist:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Catechist</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add a new catechist to this session, please enter their email address below.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCatechistForm;